import axios from 'axios'
export default class apiclient {
    constructor(){
        this.loading = false
        this.record = null
    }
    get(url) {
        this.loading = true
        return axios.get(process.env.VUE_APP_API_BASE_URL + url, this.record, {
            headers: { "Access-Control-Allow-Origin": "*" }
        })
    }
}