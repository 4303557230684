<template>
    <v-container>
        <v-stepper 
            v-model="wizardStep"
            vertical
            @change="stepChange"
            v-show="!msgSuccess"
        >
            <v-stepper-step
                :complete="wizardStep>0"
                :step="0"
            >
                <h3>Prerequisites</h3>
            </v-stepper-step>
            <v-stepper-content :step="0">
                <v-card class="pl-5">
                    <v-form>
                        <v-row>
                        <v-col
                            cols="12"
                            md="6"
                            >
                            Are you under the age of 35?
                        </v-col>
                        <v-col
                        cols="12"
                        md="6"
                        >
                            <v-radio-group
                              v-model="record.prereq__age_qualifies_yn"
                              row
                            >
                                <v-radio
                                    label="Yes"
                                    value="y"
                                ></v-radio>
                                <v-radio
                                    label="No"
                                    value="n"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            Are you a South African citizen?
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-radio-group
                              v-model="record.prereq__rsa_citizen_yn"
                              row
                            >
                                <v-radio
                                    label="Yes"
                                    value="y"
                                ></v-radio>
                                <v-radio
                                    label="No"
                                    value="n"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            Do you have a relevant civil engineering qualification?
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-radio-group
                              v-model="record.prereq__min_qualification_yn"
                              row
                            >
                                <v-radio
                                    label="Yes"
                                    value="y"
                                ></v-radio>
                                <v-radio
                                    label="No"
                                    value="n"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            Do you have at least 2 years post-highest qualification experience?
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-radio-group
                              v-model="record.prereq__min_postgrad_experience_yn"
                              row
                            >
                                <v-radio
                                    label="Yes"
                                    value="y"
                                ></v-radio>
                                <v-radio
                                    label="No"
                                    value="n"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    </v-form>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-2"
                            text
                            @click="nextTab"
                            :disabled="!section0_complete()"
                            v-show="!prerequisite_failed()"
                        >
                            Continue
                        </v-btn>
                        <span 
                            class="red--text"
                            v-show="prerequisite_failed()"
                        >Unfortunately, you do not meet the minimum requirements.
                        </span>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-stepper-content>
            <v-stepper-step
                :step="1"
                :editable="wizardStep>1"
                :complete="wizardStep>1"
                :rules="[() => section1_valid]"
            >
                <h3>Personal Information</h3>
            </v-stepper-step>
            <v-stepper-content :step="1">
                <v-form
                    ref="section1"
                    v-model="section1_valid"
                    lazy-validation
                >
                    <v-card class="pl-5">
                        <v-row>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    v-model="record.personal__first_name"
                                    :rules="[rules.required]"
                                    label="Name"
                                    type="text"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                        v-model="record.personal__last_name"
                                        :rules="[rules.required]"
                                        label="Surname"
                                        type="text"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="record.personal__date_of_birth"
                                    label="Date of Birth"
                                    type="date"
                                    style="width: 160px"
                                    @change="dobChange"
                                    :rules="rules.date_of_birth"
                                    :min="min_dob"
                                    :max="max_dob"
                                    active-picker="year"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                            <v-text-field
                                    v-model="record.personal__id_number"
                                    label="ID Number"
                                    :rules="rules.sa_id"
                                    maxlength="13"
                                    type="text"
                            ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                            <v-autocomplete
                            :items="['Female','Male','Other']"
                            :rules="[rules.required]"
                            v-model="record.personal__gender"
                            required
                            label="Gender"
                        ></v-autocomplete>
                        </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-2"
                                text
                                @click="submitSection1"
                                :disabled="!section1_valid"
                            >
                                Continue
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-stepper-content>
            <v-divider></v-divider>
            <v-stepper-step
                :step="2"
                :editable="wizardStep>2"
                :complete="wizardStep>2"
                :rules="[() => section2_valid]"
            >
                <h3>Contact Information</h3>
            </v-stepper-step>
            <v-stepper-content
                :step="2"
            >
            <v-form 
                ref="section2"
                v-model="section2_valid"
                lazy-validation
            >
                <v-card class="pl-5">
                        <v-row>
                            <v-col
                            >
                                <v-textarea
                                    v-model="record.contact__physical_address"
                                    label="Physical Address"
                                    :rules="[rules.required]"
                                    type="text"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                >
                                    <v-text-field
                                            v-model="record.contact__cellphone_number"
                                            label="Cell Phone Number"
                                            :rules="rules.phone"
                                            type="text"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        <v-row>
                            <v-col
                                >
                                <v-text-field
                                        v-model="record.contact__email"
                                        :rules="rules.email"
                                        label="Email Address"
                                        type="email"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-2"
                                text
                                @click="submitSection2"
                                :disabled="!section2_valid"
                            >
                            Continue
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-form>
            </v-stepper-content>
            <v-divider></v-divider>
            <v-stepper-step 
                :step="3"
                :editable="wizardStep>3"
                :complete="wizardStep>3"
                :rules="[() => section3_valid]"
            >
                <h3>Employment Information</h3>
            </v-stepper-step>
            <v-stepper-content :step="3">
                <v-form 
                    ref="section3"
                    v-model="section3_valid"
                    lazy-validation
                >
                <v-card class="pl-5">
                    <v-row>
                <v-col
                    cols="12"
                    md="6"
                    >
                    Total numbers of years work in the engineering sector:
                </v-col>
                <v-col
                        cols="12"
                        md="6"
                        >
                        <v-text-field
                        v-model="record.employment__engineering_years"
                        type="number"
                        :rules="[rules.required]"
                        ></v-text-field>
                </v-col>
                </v-row>
                <v-row>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            Are you currently employed?
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-radio-group
                              v-model="record.employment__currently_employed_yn"
                              row
                              :rules="[rules.required]"
                            >
                                <v-radio
                                    label="Yes"
                                    value="y"
                                ></v-radio>
                                <v-radio
                                    label="No"
                                    value="n"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                <v-row
                    v-show="record.employment__currently_employed_yn=='y'"
                >
                    <v-col
                    cols="12"
                    md="6"
                    >
                    Current Employer:
                </v-col>
                <v-col
                cols="12"
                md="6"
                >
                <v-text-field
                v-model="record.employment__current_employer"
                type="text"
                :rules="record.employment__currently_employed_yn=='y' ? [rules.required] : []"
                ></v-text-field>
            </v-col>
            </v-row>
            <v-row
                    v-show="record.employment__currently_employed_yn=='y'"
                    >
                        <v-col
                        cols="12"
                        md="6"
                        >
                        What sector of engineering you are currently employed in:
                    </v-col>
                    <v-col
                    cols="12"
                    md="6"
                    >
                    <v-autocomplete
                        :items="['Civil Engineering', 'Other']"
                        v-model="record.employment__current_sector"
                        :rules="record.employment__currently_employed_yn=='y' ? [rules.required] : []"
                    ></v-autocomplete>
                </v-col>
            </v-row
            >
            <v-row
                v-show="record.employment__currently_employed_yn=='y'"
                >
                    <v-col
                    cols="12"
                    md="6"
                    >
                        Number of years with your current employer:
                    </v-col>
                    <v-col
                        cols="12"
                        md="6"
                    >
                        <v-text-field
                            v-model="record.employment__current_employer_years"
                            type="number"
                            :rules="record.employment__currently_employed_yn=='y' ? [rules.required] : []"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row
                    v-show="record.employment__currently_employed_yn=='y'"
                >
                    <v-col
                        cols="12"
                        md="6"
                    >
                        Degree of responsibility for your current employer:
                    </v-col>
                    <v-col
                        cols="12"
                        md="6"
                    >
                    <v-autocomplete
                        :items="['Exposed', 'Assisting', 'Participating', 'Contributing', 'Performing']"
                        v-model="record.employment__current_employer_responsibility"
                        :rules="record.employment__currently_employed_yn=='y' ? [rules.required] : []"
                    ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer></v-spacer>
                            <v-btn
                            color="blue darken-2"
                            text
                            @click="submitSection3"
                            :disabled="!section3_valid"
                            >
                            Continue
                        </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
                </v-card>
            </v-form>
            </v-stepper-content>
            <v-divider></v-divider>
            <v-stepper-step 
                :step="4"
                :editable="wizardStep>4"
                :complete="wizardStep>4"
                :rules="[() => section4_valid]"
            >
                <h3>Qualification</h3>
            </v-stepper-step>
            <v-stepper-content :step="4">
                <v-form
                    ref="section4"
                    v-model="section4_valid"
                    lazy-validation
                >
                <v-card class="pl-5">
                    <v-row>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            Are you currently still studying?
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-radio-group
                                v-model="record.qualification__studying_yn"
                                row
                                :rules="[rules.required]"
                            >
                                <v-radio
                                    label="Yes"
                                    value="y"
                                ></v-radio>
                                <v-radio
                                    label="No"
                                    value="n"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            What is your highest completed qualification:
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-autocomplete
                                :items="qualifications_list"
                                item-text="name"
                                item_value="value"
                                v-model="record.qualification__highest"
                                :rules="[rules.required]"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            Number of years experience after obtaining your higest qualification:
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-text-field
                                v-model="record.qualification__highest_years_experience"
                                type="number"
                                minvalue="0"
                                :rules="[rules.required]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                            <v-btn
                            color="blue darken-2"
                            text
                            @click="submitSection4"
                            :disabled="!section4_valid"
                            >
                                Continue
                            </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-form>
            </v-stepper-content>
            <v-divider></v-divider>
            <v-stepper-step
                :step="5"
                :editable="wizardStep>5"
                :complete="wizardStep>5"
                :rules="[() => section5_valid]"
            >
                <h3>Experience</h3>
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-content :step="5">
                <v-form
                    ref="section5"
                    v-model="section5_valid"
                    lazy-validation
                >
                <v-card class="pl-5">
                    <v-card-text
                        class="mb-12"
                        color="grey lighten-1"
                        height="200px"
                    >
                </v-card-text>
                <v-row>
                    <v-col
                    cols="12"
                    md="6"
                    >
                    Are you exposed to any broadly defined Engineering problems at present. If yes, please elaborate.
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                        <v-radio-group
                                v-model="record.experience__engineering_problems_yn"
                                row
                                :rules="[rules.required]"
                            >
                                <v-radio
                                    label="Yes"
                                    value="y"
                                ></v-radio>
                                <v-radio
                                    label="No"
                                    value="n"
                                ></v-radio>
                            </v-radio-group>
                            <v-text-field
                                :disabled="record.experience__engineering_problems_yn != 'y'"
                                :label=" record.experience__engineering_problems_yn == 'n' ? 'n/a' : 'Provide Detail'"
                                v-model="record.experience__engineering_problems_detail"
                                type="text"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                    <v-col
                    cols="12"
                    md="6"
                    >
                    Are you exposed to, or involved with any Design work? If yes, please elaborate.
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                        <v-radio-group
                                v-model="record.experience__design_work_yn"
                                row
                                :rules="[rules.required]"
                            >
                                <v-radio
                                    label="Yes"
                                    value="y"
                                ></v-radio>
                                <v-radio
                                    label="No"
                                    value="n"
                                ></v-radio>
                            </v-radio-group>
                            <v-text-field
                                :disabled="record.experience__design_work_yn != 'y'"
                                :label=" record.experience__design_work_yn == 'n' ? 'n/a' : 'Detail'"
                                v-model="record.experience__design_work_detail"
                                type="text"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                    <v-col
                    cols="12"
                    md="6"
                    >
                    Do you have any experience with the implementation of Design solutions? If yes, please elaborate.
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                        <v-radio-group
                                v-model="record.experience__implementation_yn"
                                row
                                :rules="[rules.required]"
                            >
                                <v-radio
                                    label="Yes"
                                    value="y"
                                ></v-radio>
                                <v-radio
                                    label="No"
                                    value="n"
                                ></v-radio>
                            </v-radio-group>
                            <v-text-field
                                :disabled="record.experience__implementation_yn != 'y'"
                                :label=" record.experience__implementation_yn == 'n' ? 'n/a' : 'Detail'"
                                v-model="record.experience__implementation_detail"
                                type="text"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            md="6"
                        >
                        Are you responsible for the management of any engineering activities? If yes, please elaborate.
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                        <v-radio-group
                                v-model="record.experience__management_yn"
                                row
                                :rules="[rules.required]"
                            >
                                <v-radio
                                    label="Yes"
                                    value="y"
                                ></v-radio>
                                <v-radio
                                    label="No"
                                    value="n"
                                ></v-radio>
                            </v-radio-group>
                            <v-text-field
                                :disabled="record.experience__management_yn != 'y'"
                                :label=" record.experience__management_yn == 'n' ? 'n/a' : 'Detail'"
                                v-model="record.experience__management_detail"
                                type="text"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            md="6"
                        >
                        Do you liaise with any stakeholders when managing Engineering activities? If yes, please elaborate.
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                        <v-radio-group
                                v-model="record.experience__stakeholders_yn"
                                row
                                :rules="[rules.required]"
                            >
                                <v-radio
                                    label="Yes"
                                    value="y"
                                ></v-radio>
                                <v-radio
                                    label="No"
                                    value="n"
                                ></v-radio>
                            </v-radio-group>
                            <v-text-field
                                :disabled="record.experience__stakeholders_yn != 'y'"
                                :label=" record.experience__stakeholders_yn == 'n' ? 'n/a' : 'Detail'"
                                v-model="record.experience__stakeholders_detail"
                                type="text"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            md="6"
                        >
                        Do you have experience working with the Occupational Health and Safety Acts and Regulations. If yes, please elaborate.
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                        <v-radio-group
                                v-model="record.experience__health_safety_yn"
                                row
                                :rules="[rules.required]"
                            >
                                <v-radio
                                    label="Yes"
                                    value="y"
                                ></v-radio>
                                <v-radio
                                    label="No"
                                    value="n"
                                ></v-radio>
                            </v-radio-group>
                            <v-text-field
                                :disabled="record.experience__health_safety_yn != 'y'"
                                :label=" record.experience__health_safety_yn == 'n' ? 'n/a' : 'Detail'"
                                v-model="record.experience__health_safety_detail"
                                type="text"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            md="6"
                        >
                        Are you responsiblie for making decisions on part, or all of the Engineering activities that you deal with. If yes, please elaborate.
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                        <v-radio-group
                                v-model="record.experience__decisions_yn"
                                row
                                :rules="[rules.required]"
                            >
                                <v-radio
                                    label="Yes"
                                    value="y"
                                ></v-radio>
                                <v-radio
                                    label="No"
                                    value="n"
                                ></v-radio>
                            </v-radio-group>
                            <v-text-field
                                :disabled="record.experience__decisions_yn != 'y'"
                                :label=" record.experience__decisions_yn == 'n' ? 'n/a' : 'Detail'"
                                v-model="record.experience__decisions_detail"
                                type="text"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            md="6"
                        >
                        Do you undergo any professional development activities (training) to extend your level of competence? If yes, please elaborate.
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                        <v-radio-group
                                v-model="record.experience__professional_development_yn"
                                row
                                :rules="[rules.required]"
                            >
                                <v-radio
                                    label="Yes"
                                    value="y"
                                ></v-radio>
                                <v-radio
                                    label="No"
                                    value="n"
                                ></v-radio>
                            </v-radio-group>
                            <v-text-field
                                :disabled="record.experience__professional_development_yn != 'y'"
                                :label=" record.experience__professional_development_yn == 'n' ? 'n/a' : 'Detail'"
                                v-model="record.experience__professional_development_detail"
                                type="text"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                            <v-btn
                            color="blue darken-2"
                            text
                            @click="submitSection5"
                            :disabled="!section5_valid"
                            >
                                Continue
                            </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-form>
            </v-stepper-content>
            <v-stepper-step
                :step="6"
                :editable="wizardStep>6"
                :complete="wizardStep>6"
                :rules="[() => section6_valid]"
            >
                <h3>Declaration</h3>
            </v-stepper-step>
            <v-stepper-content :step="6">
                <v-form
                    ref="section6"
                    v-model="section6_valid"
                    lazy-validation
                >
                <v-card class="pl-5">
                    <v-row>
                        <v-col
                        cols="12"
                        md="7"
                        >
                        That I have never been removed from an office of trust on account of improper conduct.
                        </v-col>
                        <v-col
                            cols="12"
                            md="5"
                        >
                            <v-radio-group
                              v-model="record.declaration__never_removed_from_office_yn"
                              row
                              :rules="[rules.required]"
                            >
                                <v-radio
                                    label="Yes"
                                    value="y"
                                ></v-radio>
                                <v-radio
                                    label="No"
                                    value="n"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            md="7"
                        >
                            I have never been convicted of an offence in the Republic, other than an offence committed prior to 27 April 1994 associated with political objectives, and was sentenced to imprisonment without an option of a fine, or, in the case of fraud, to a fine or imprisonment or both.
                        </v-col>
                        <v-col
                            cols="12"
                            md="5"
                        >
                            <v-radio-group
                              v-model="record.declaration__never_convicted_rsa_yn"
                              row
                              :rules="[rules.required]"
                            >
                                <v-radio
                                    label="Yes"
                                    value="y"
                                ></v-radio>
                                <v-radio
                                    label="No"
                                    value="n"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            md="7"
                        >
                            I have never been convicted of an offence in a foreign country and sentenced to imprisonment without an option of a fine, or, in the case of fraud, to a fine or imprisonment or both.
                        </v-col>
                        <v-col
                            cols="12"
                            md="5"
                        >
                            <v-radio-group
                              v-model="record.declaration__never_convicted_foreign_yn"
                              row
                              :rules="[rules.required]"
                            >
                                <v-radio
                                    label="Yes"
                                    value="y"
                                ></v-radio>
                                <v-radio
                                    label="No"
                                    value="n"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            md="7"
                        >
                            I have never been declared by the High Court to be of unsound mind or mentally disordered, or is detained under the Mental Health Act, 1973;
                        </v-col>
                        <v-col
                            cols="12"
                            md="5"
                        >
                            <v-radio-group
                              v-model="record.declaration__never_unsound_yn"
                              row
                              :rules="[rules.required]"
                            >
                                <v-radio
                                    label="Yes"
                                    value="y"
                                ></v-radio>
                                <v-radio
                                    label="No"
                                    value="n"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            md="7"
                        >
                        That I have never been disqualified from registration as a result of any punishment imposed on me under this Act
                        </v-col>
                        <v-col
                            cols="12"
                            md="5"
                        >
                            <v-radio-group
                              v-model="record.declaration__never_disqualified_yn"
                              row
                              :rules="[rules.required]"
                            >
                                <v-radio
                                    label="Yes"
                                    value="y"
                                ></v-radio>
                                <v-radio
                                    label="No"
                                    value="n"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            md="7"
                        >
                        I have never been declared rehabilitated insolvent whose insolvency was caused by my negligence or incompetence in performing work falling within the scope of my registration(s).
                        </v-col>
                        <v-col
                            cols="12"
                            md="5"
                        >
                            <v-radio-group
                              v-model="record.declaration__never_rehab_insolvent_yn"
                              row
                              :rules="[rules.required]"
                            >
                                <v-radio
                                    label="Yes"
                                    value="y"
                                ></v-radio>
                                <v-radio
                                    label="No"
                                    value="n"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col
                            cols="12"
                            md="3"
                        >
                        <v-btn
                            :disabled="saving"
                            color="blue"
                            @click="submitSection6"
                        >
                            Submit Application
                        </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                </v-card>
                </v-form>
            </v-stepper-content>
        </v-stepper>
        <v-card 
            v-if="msgSuccess"
            class="d-flex flex-column justify-center py-16"
        >
                <v-icon
                    large
                    color="green darken-2"
                >
                    mdi-check
                </v-icon>
                <div class="text-center">
                        <h3>
                        Your application has been submitted! <br/>Reference number: <strong>{{ application_ref_no }}</strong>
                    </h3>
                </div>
                <v-btn
                    color="blue darken-2"
                    text
                    @click="restart"
                >
                    Capture another
                </v-btn>    
        </v-card>
        <v-snackbar
            :value="msgVisible"
            :color="msgSuccess ? 'success' : 'red'"
            timeout="15000"
        >
            {{ msgText }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    :_color="msgSuccess? 'green': 'pink'"
                    text
                    v-bind="attrs"
                    @click="msgVisible = false"
                >
                    Dismiss
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import apiClient from '../apiClient'
import moment from 'moment'
const maxApplicantAge = 35
const min_dob = moment().add(-maxApplicantAge, 'years').format('yyyy-MM-DD')
const max_dob = moment().add(-7, 'years').format('yyyy-MM-DD')
export default {
    data: () => ({
        api: new apiClient(),
        wizardStep: 0,
        min_dob: min_dob,
        max_dob: max_dob,
        section1_valid: true,
        section2_valid: true,
        section3_valid: true,
        section4_valid: true,
        section5_valid: true,
        section6_valid: true,
        rules: { 
            required: v => !!v || 'A value is required',
            sa_id: [
                v => !!v || 'An ID number is required',
                v => /^\d{13}$/.exec(v) || 'A valid South African ID number format is expected',
            ],
            email: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            date_of_birth: [
                v => !!v || 'Date is required',
                v => v >= min_dob || `Applicants must be below ${ maxApplicantAge} years of age`,
                v => v <= max_dob || `Please correct`
            ],
            phone: [
                v => !!v || 'Phone number is required',
                v => /^\+?\d+$/.exec(v) || 'Phone number must be valid, without spaces',
                v => !!v && v.length >= 10 || 'Phone number is too short'
            ],
        },
        loading: false,
        storage: null,
        historyPoller: { poll: () => { alert('handler not implemented') } },
        msgSuccess: false,
        msgVisible: false,
        msgText: '',
        record: {},
        saving: false,
        token: '',
        qualifications_list: 
        [
            { name: 'National Diploma in Civil Engineering', value: 'national_diploma' }, 
            { name: 'Advanced Diploma in Civil Engineering', value: 'advanced_diplopma' }, 
            { name: 'Bachelor of Technology in Civil Engineering' , value: 'bachelor_technology' }, 
            { name: 'Bachelor of Engineering Technology Honours', value: 'bachelor_engineering' }, 
            { name: 'Master of Engineering in Civil Engineering', value: 'masters' }, 
            { name: 'Doctor of Engineering in Civil Engineering', value: 'doctorate' }
        ],
        application_ref_no: ''
    }),
    async mounted() {
        this.record = this.initRecord()
    },
    components: {
        // ErrorDisplay: () => import('../components/ErrorDisplay'),
        // ChangeLog: () => import('../components/ChangeLog'),
    },
    methods: {
        async recaptcha() {
            this.token = await this.$recaptcha('login')
            this.record.token = this.token
            //console.log(this.token)
        },
        initRecord() {
            return {
                personal__date_of_birth: min_dob
            }
        },
        restart() {
            this.msgSuccess = false;
            this.wizardStep = 0
            this.token = ''
            this.application_ref_no = ''
            this.record = this.initRecord()
            this.section1_valid = this.section2_valid = this.section3_valid = this.section4_valid = this.section5_valid = this.section6_valid
        },
        submitSection1() {
            if (this.$refs.section1.validate())
                this.nextTab()
        },
        submitSection2() {
            if (this.$refs.section2.validate())
                this.nextTab()
        },
        submitSection3() {
            if (this.$refs.section3.validate())
                this.nextTab()
        },
        submitSection4() {
            if (this.$refs.section4.validate())
                this.nextTab()
        },
        submitSection5() {
            if (this.$refs.section5.validate())
                this.nextTab()
        },
        submitSection6() {
            if (this.$refs.section6.validate())
                this.submit()
        },
        dobChange() {
            //if (!this.record.id_number)
                //this.record.id_number = this.record.date_of_birth
        },
        success(msg) {
            this.msgSuccess = true
            this.msgText = msg
        },
        error(msg) {
            this.msgSuccess = false
            this.msgText = msg
            this.msgVisible = true
        },
        previousTab() {
            this.wizardStep -= 1;
        },
        nextTab() {
            this.wizardStep += 1;
            
        },
        stepChange(step) {
            console.log(step)
        },
        section0_complete() {
            return this.record.prereq__age_qualifies_yn
            &&     this.record.prereq__rsa_citizen_yn
            &&     this.record.prereq__min_postgrad_experience_yn
            &&     this.record.prereq__min_qualification_yn
        },
        prerequisite_failed() {
            return this.record.prereq__age_qualifies_yn == 'n'
            || this.record.prereq__rsa_citizen_yn == 'n'
            || this.record.prereq__min_postgrad_experience_yn == 'n'
            || this.record.prereq__min_qualification_yn == 'n'
        },
        async submit() {
            this.msgVisible = false;
            if (this.validateSubmit()) {
                this.saving = true;
                await this.recaptcha()
                this.axios.post(process.env.VUE_APP_API_BASE_URL + '/signup', this.record, {
                    headers: { "Access-Control-Allow-Origin": "*" }
                }).then((res) => {
                    this.saving = false
                    if (res.status == 200) {
                        console.log('success')
                        this.application_ref_no = res.data;
                        this.success(this.application_ref_no)
                    }
                }).catch((err) => {
                    this.saving = false
                    var msgText = err.message + ' – ' 
                    if (err.response)
                        msgText += (err.response.data || '')
                    else 
                        msgText += 'no additional info available'
                    this.error(msgText)
                })
            }
            else {
                this.error('Some information could not be submitted. Please review above sections.')
            }
        },
        validateSubmit() {
            return this.$refs.section1.validate()
            && this.$refs.section2.validate()
            && this.$refs.section3.validate()
            && this.$refs.section4.validate()
            && this.$refs.section5.validate()
            && this.$refs.section6.validate()
        },
    }
}
</script>